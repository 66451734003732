import React from 'react'
import { RouteComponentProps } from 'types/reach-router'

import './VerifyEmail.scss'

interface VerifyEmailProps extends RouteComponentProps {
  code?: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function VerifyEmail(props: VerifyEmailProps) {
  return (
    <div className="email-verification row justify-content-center">
      <div className="col-md-8 col-lg-6">
        <h1>Signing Up for Jevitty?</h1>
        <p className="email-verification__instruction__title">
          Please do the following:
        </p>
        <ol className="email-verification__instruction__list">
          <li className="email-verification__instruction__list__item">
            Reopen the verification email that got you here. But this time,{' '}
            <em>do it on your mobile device</em>.
          </li>
          <li className="email-verification__instruction__list__item">
            Tap&nbsp;on the &ldquo;Verify My Email&rdquo; button.
            Jevitty&nbsp;app will open and you&apos;ll be able to finish your
            account&nbsp;creation.
          </li>
        </ol>

        <p>
          You&apos;ve got here from the verification email we&apos;ve sent you
          recently. For&nbsp;now, you&nbsp;have to use your mobile device with
          the Jevitty app installed to sign&nbsp;up. Signing&nbsp;up on the web
          (here) is not available&nbsp;yet.
        </p>
        <p>
          Sorry for the inconvenience and see you soon in the Jevitty&nbsp;app.
        </p>
      </div>
    </div>
  )
}
